import { createSlice } from '@reduxjs/toolkit';

export const isDropdownMenuOpenSlice = createSlice({
	name: 'IsDropdownMenuOpen',
	initialState: {
		isDropdownMenuOpen: false,
	},
	reducers: {
		setIsDropdownMenuOpen: (state, action) => {
			state.isDropdownMenuOpen = action.payload;
		},
	},
});

export const { setIsDropdownMenuOpen } = isDropdownMenuOpenSlice.actions;

export default isDropdownMenuOpenSlice.reducer;
