import { createGlobalStyle } from 'styled-components';

const MainStyle = createGlobalStyle`

  :root {
	--background-color: ${({ theme }) => theme.colors.beige};
  }
  
  // rems for 16px
  // 10px = 0.625rem
  // 11px = 0.6875rem
  // 12px = 0.75rem
  // 13px = 0.8125rem
  // 14px = 0.875rem
  // 15px = 0.9376rem
  // 16px = 1rem
  // 17px = 1.0625rem
  // 18px = 1.125rem
  // 19px = 1.1876rem
  // 20px = 1.25rem
  // 22px = 1.375rem
  // 23px = 1.4375rem
  // 24px = 1.5rem
  // 25px = 1.5625rem
  // 26px = 1.625rem
  // 30px = 1.875rem

  html {
    transition: background-color 0.1s ease-in;
    background-color: var(--background-color);
    padding: 0;
    width: 100%;
    height: 100%;
    caret-color: rgba(0, 0, 0, 0);
	position: relative;
  }

  body {
	padding: 0;
    -webkit-overflow-scrolling: touch;
	margin: 0;
    position: relative;
	font-family: ${({ theme }) => theme.fontFamily.main};
    color: ${({ theme }) => theme.colors.white};
    -webkit-tap-highlight-color: transparent !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
  }


  *, *::before, *::after {
	box-sizing: border-box;
  }

  textarea:focus, input:focus {
	outline: none;
  }

  h1, h2, h3, h4, h5, h6, p, ul {
	margin: 0;
	padding: 0;
  }
  #___gatsby{
	overflow-x: hidden;
	position: relative;
  }
  .pin-spacer-backgroundCircle{
    //overflow: hidden;
  }
  
  .pin-spacer-logo{
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
  
`;

export default MainStyle;
